import React, { useCallback, useState, useContext, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, CircularProgress, Link } from '@mui/material';
import Modal from '../common/Modal';
import TextFieldAct from '../mui/TextFieldAct';
import enviarMultipart, { enviar } from '../../services/BaseService';
import SesionContext from '../../contexts/SesionContext';
import Card from '../common/Card';
import FormAct from '../common/FormAct';
import { validarAnio, validarFechaValoracion, validarPorcentaje } from '../util/Regex'
import ButtonAct from '../mui/ButtonAct';
import { useNavigate } from 'react-router-dom';

const CargaDeArchivo = (props) => {
    const sep = ";;";//separador 
    const navigate = useNavigate();
    const sesionCtx = useContext(SesionContext);
    const [cargaInicial, setCargaInicial] = useState(false);
    const [cargaInicialMostrar, setCargaInicialMostrar] = useState('no');
    const [fechaValoracion, setFechaValoracion] = useState('');
    const [primerEstudio, setPrimerEstudio] = useState('');
    const [calculosAutomaticos, setCalculosAutomaticos] = useState(false);
    const [tasaPrincipal, setTasaPrincipal] = useState(null);
    const [reconocimiento, setReconocimiento] = useState('ERI');
    const [reemplazar, setReemplazar] = useState(false);

    //const [guardarConTasasDto, setGuardarConTasasDto] = useState({});


    const [errores, setErrores] = useState({ fechaValoracion: null, primerEstudio: null });

    const [erroresTasas, setErroresTasas] = useState([]);
    const [valoresTasas, setValoresTasas] = useState([]);
    const [valoresTasasCargaInicial, setValoresTasasCargaInicial] = useState([]);

    const [archivoSeleccionado, setArchivoSeleccionado] = useState(null);

    const [modalVisible, setModalVisible] = useState(false);

    const [modalTasasVisible, setModalTasasVisible] = useState(false);

    const [modalTasasVisualizacionVisible, setModalTasasVisualizacionVisible] = useState(false);

    const [modalSimpleVisible, setModalSimpleVisible] = useState(false);

    const [respuesta, setRespuesta] = useState({ tipo: 'A', cargaOk: false, errores: [], advertencias: [], informativas: [] });

    const [cargaSplit, setCargaSplit] = useState('');

    const [clienteSplit, setClienteSplit] = useState('');

    const [mismaTasaGeneral, setMismaTasaGeneral] = useState(false);

    useEffect(() => {
        const verificarCargaInicial = async () => {
            sesionCtx.cambiarLoading(true);
            const response = await enviar().get(`base-consolidada/carga-inicial/${sesionCtx.cliente.ruc}`).then(response => {
                if (response.status === 200 || response.status === 201) {
                    setFechaValoracion('');
                    setPrimerEstudio('');
                    setCalculosAutomaticos(false);
                    setReconocimiento('ERI');
                } else {
                    setCargaInicialMostrar('si');
                    setCargaInicial(true);
                }
            })
            .catch(error => {
                setCargaInicialMostrar('si');
                setCargaInicial(true);
            });

            sesionCtx.cambiarLoading(false);
        };
    
        verificarCargaInicial();
    }, []);
    
    const onChangeCargaInicial = useCallback(async (event) => {
        const value = event.target?.value === "si";
        setCargaInicialMostrar(event.target.value)
        setCargaInicial(value);
        if (value) {
            sesionCtx.cambiarLoading(true);
            try {
                const response = await enviar().get(`base-consolidada/carga-inicial/${sesionCtx.cliente.ruc}`);
                if (response.status === 200 || response.status === 201) {
                    setFechaValoracion(response.data.fechaDeValoracionInterfaz);
                    setPrimerEstudio(response.data.primerEstudio);
                    setCalculosAutomaticos(response.data.calculosAutomaticos);
                    setReconocimiento(response.data.reconocimientoInicial);
                    setValoresTasasCargaInicial(response.data.tasasPorAnioVisualizacion);
                    setTasaPrincipal(response.data.tasa);
                    //setPorcentajeNumero(response.data.tasa);
                    //setPorcentaje(`${response.data.tasa}%`);
                }
            } catch (error) { } finally {
                sesionCtx.cambiarLoading(false);
            }
        } else {
            setFechaValoracion('');
            setPrimerEstudio('');
            setCalculosAutomaticos(false);
            setReconocimiento('ERI');
            //setPorcentajeNumero('');
            //setPorcentaje(0);
        }
    }, [sesionCtx]);

    const onChangeArchivo = (event) => {
        setArchivoSeleccionado(event.target.files[0]);
    };

    const onChangeFechaValoracion = (event) => {
        setFechaValoracion(event.target.value);
        if (event.target.value !== '') {
            let mensaje = validarFechaValoracion(event.target.value);
            console.log("---->" + mensaje)
            setErrores((anterior) => { return { ...anterior, fechaValoracion: mensaje } });
        } else {
            setErrores((anterior) => { return { ...anterior, fechaValoracion: null } });
        }
    }

    const onChangePrimerEstudio = (event) => {
        setPrimerEstudio(event.target.value);
        if (event.target.value !== '') {
            let mensaje = validarAnio(event.target.value);
            setErrores((anterior) => { return { ...anterior, primerEstudio: mensaje } });
        } else {
            setErrores((anterior) => { return { ...anterior, primerEstudio: null } });
        }
    }

    const onChangeCalculosAutomaticos = (event) => {
        setCalculosAutomaticos(event.target.value);
    }

    const onClickVisualizarTasas = (event) => {
        setModalTasasVisualizacionVisible(!modalTasasVisualizacionVisible);
    }

    const onChangeAplicaMismaTasaGeneral = (event, index) => {
        console.log("event.target.checked", event.target.checked);
        const checked = event.target.checked;

        setMismaTasaGeneral(checked);

        const nuevosValoresTasa = [...valoresTasas];

        if (checked) {

            const tasaGeneral = nuevosValoresTasa[index];
            nuevosValoresTasa.map(t => {
                t.tasaString = tasaGeneral.tasaString;
                t.tasa = tasaGeneral.tasa;
            });
        } else {
            nuevosValoresTasa.map(t => {
                if (t.anio != 2018) {
                    t.tasaString = '';
                    t.tasa = 0;
                }
            });
        }

        setValoresTasas(nuevosValoresTasa);
    }

    const onChangeValoresTasa = (event, index) => {
        console.log("onChangeValoresTasa....", event.target.value, " index:", index);
        if (cargaInicial) {
            setMismaTasaGeneral(false);
        } else {
            setMismaTasaGeneral(true);
        }
        //setValoresTasas[]
        //setPorcentaje(event.target.value);
        const nuevosValoresTasa = [...valoresTasas];
        nuevosValoresTasa[index].tasaString = event.target.value;
        let mensaje = validarPorcentaje(event.target.value, "Ingrese porcentaje válido. Ej: 12.5%");
        console.log("mensaje....", mensaje);
        const nuevosErroresTasas = [...erroresTasas];
        nuevosErroresTasas[index] = mensaje;

        if (mensaje == null) {
            const numero = event.target.value.replace('%', '');
            nuevosValoresTasa[index].tasa = parseFloat(numero).toFixed(2);
            nuevosErroresTasas[index] = "";
            setValoresTasas(nuevosValoresTasa);
            console.log(parseFloat(numero).toFixed(2))
        } else {
            nuevosValoresTasa[index].tasa = parseFloat(0).toFixed(2);
        }

        setErroresTasas(nuevosErroresTasas);

    }

    const onChangeReconocimiento = (event) => {
        setReconocimiento(event.target.value);
    }

    const onChangeReemplazar = (event) => {
        setReemplazar(event.target.value);
    }

    const onCloseModal = () => {
        setModalVisible(false);
        setArchivoSeleccionado(null);
    };

    const onCloseModalTasas = () => {
        setModalTasasVisible(false);
        setArchivoSeleccionado(null);
    };

    const onCloseModalTasasVisualizacion = () => {
        setModalTasasVisualizacionVisible(false);
    };

    const onCloseModalSimple = () => {
        setModalSimpleVisible(false);
        setModalVisible(false);
    };

    const onClickContinuar = () => {
        navigate('/base-consolidada');
    };

    const borrarInputFile = () => {
        const inputFile = document.getElementById('file-archivo');
        inputFile.value = "";
    }

    const crearFormData = useCallback(() => {

        console.log("archivoSeleccionado.name:" + archivoSeleccionado.name);
        const fd = new FormData();
        fd.append('archivo', archivoSeleccionado);
        let clienteSplitTmp = sesionCtx.cliente.id + sep + sesionCtx.cliente.ruc + sep + sesionCtx.cliente.nombre + sep;
        if (sesionCtx.cliente.nombreComercial != null) {
            clienteSplitTmp += sesionCtx.cliente.nombreComercial;
        }
        clienteSplitTmp += sep;
        setClienteSplit(clienteSplitTmp);
        console.log("clienteSplit: " + clienteSplitTmp)
        fd.append('cliente', clienteSplitTmp);


        const [mesValoracion, anioValoracion] = fechaValoracion.split("/");

        let cargaSplitTmp = cargaInicial + sep + anioValoracion + sep + mesValoracion + sep + archivoSeleccionado.name + sep + primerEstudio + sep + calculosAutomaticos + sep + reconocimiento + sep + reemplazar + sep;
        setCargaSplit(cargaSplitTmp);
        console.log("cargaSplitTmp: " + cargaSplitTmp)
        fd.append('carga', cargaSplitTmp);
        //fd.append('carga', '2021;;07;;2003;;true;;30;;ERI');
        return fd;
    }, [archivoSeleccionado, calculosAutomaticos, cargaInicial, fechaValoracion, primerEstudio, reconocimiento, reemplazar, sesionCtx.cliente]);

    const onClickCargarArchivo = useCallback((event) => {

        //para borrar si en el caso que cambie el archivo
        borrarInputFile();
        sesionCtx.cambiarLoading(true);

        const formData = crearFormData();

        trackPromise(

            enviarMultipart().post('/historicos/validar-cargar-archivo/', formData).then(function (response) {
                console.log("OK?: " + response.status);
                if (response.status === 200) {
                    const respuestaAhora = response.data;
                    console.log("response.data?: ", response.data);
                    console.log("cargaOk?: ", respuestaAhora.cargaOk);
                    setRespuesta(respuestaAhora);


                    // se abre la popup para ingresar las tasas
                    const tasasIR = [];
                    const erroresTasa = [];
                    if (response.data.aniosParaTasasIR.length > 0) {
                        response.data.aniosParaTasasIR.forEach(a => {
                            tasasIR.push({ anio: a, tasa: 0, tasaString: '' })
                            erroresTasa.push('');
                        }
                        );
                    }

                    setErroresTasas(erroresTasa);
                    setValoresTasas(tasasIR);


                    if (respuestaAhora.advertencias.length > 0 || respuestaAhora.errores.length > 0) {
                        console.log("Carga con advertencia o errores");
                        console.log("Informativas: " + respuestaAhora.informativas.length);
                        setModalVisible(true);
                    } else {
                        // se abre la popup para ingresar las tasas

                        setModalTasasVisible(true);

                    }

                } else {
                    alert("Error al procesar archivo");
                }
                sesionCtx.cambiarLoading(false);
            }).catch(function (error) {
                alert("Error al procesar archivo");
                console.log(error);
                sesionCtx.cambiarLoading(false);
            }

            ));


    }, [crearFormData, sesionCtx]);//las variables del state q se van a usar en la funcion, como en el useEffect

    const onClickGuardarConTasas = useCallback(async () => {
        sesionCtx.cambiarLoading(true);
        try {
            console.log("mismaTasaGeneral", mismaTasaGeneral)
            const params = {
                mismaTasa: null,
                tasas: valoresTasas,
                carga: cargaSplit,
                cliente: clienteSplit
            };

            if (mismaTasaGeneral) {
                params.mismaTasa = valoresTasas[0].tasa;
                params.tasas = null;
            }

            console.log('paramas', params)

            const response = await enviar().post('/historicos/guardar-con-tasas/', params);
            const respuestaAhora = response.data;
            setRespuesta(respuestaAhora);

            if (respuestaAhora.cargaOk) {
                console.log("Carga correcta :)");
                console.log("Informativas: " + respuestaAhora.informativas.length);
            } else {
                console.log("Errores: " + respuestaAhora.errores.length);
            }
            setModalSimpleVisible(true);
        } catch (error) {
            console.log(error);
        } finally {
            sesionCtx.cambiarLoading(false);
        }


    }, [cargaSplit, clienteSplit, sesionCtx, valoresTasas]);


    const onClickContinuarConAdvertencias = useCallback(async () => {
        // se abre la popup para ingresar las tasas
        sesionCtx.cambiarLoading(true);
        // const tasasIR = [];
        // const erroresTasa = [];
        // if (response.data.aniosParaTasasIR.length > 0) {
        //     response.data.aniosParaTasasIR.forEach(a => {
        //         tasasIR.push({ anio: a, tasa: 0, tasaString: '' })
        //         erroresTasa.push('');
        //     }
        //     );
        // }
        setModalVisible(false);
        setModalTasasVisible(true);
        sesionCtx.cambiarLoading(false);

    }, [sesionCtx]);

    const deshabilitarBotonGuardar = () => {
        return fechaValoracion === '' || (cargaInicial === true && primerEstudio === '') || archivoSeleccionado == null || errores.fechaValoracion != null || errores.primerEstudio != null;
    }

    const deshabilitarBotonGuardarConTasas = () => {

        let habilitado = true;

        if (mismaTasaGeneral && valoresTasas[0].tasa > 0) {
            habilitado = true;
        }


        if (!mismaTasaGeneral) {
            valoresTasas.map(t => {
                if (t.tasa <= 0) {
                    habilitado = false;
                }
            })
        }

        console.log("habilitado", habilitado)

        return !habilitado;
    }

    return (
        <Card className="derecha" titulo="Carga de archivo" descripcion="Ingrese la información de la carga y suba el archivo con la información de los empleados">

            <FormAct>

                <FormControl className='control'>
                    <FormLabel focused={false} className='label'>Carga inicial</FormLabel>
                    <RadioGroup row
                        name="rd-carga"
                        value={cargaInicialMostrar}
                        onChange={onChangeCargaInicial}>
                        <FormControlLabel className='radio-opcion' value="si" control={<Radio />} label="SI" />
                        <FormControlLabel className='radio-opcion' value="no" control={<Radio />} label="NO" />

                    </RadioGroup>
                </FormControl>

                <FormControl className='control'>
                    <TextFieldAct
                        required
                        id="txt-anio-valoracion"
                        placeholder="Ej. 12/2022"
                        label="Año de valoración"
                        helperText={errores.fechaValoracion || 'Ingrese la fecha de valoración del archivo a cargar'}
                        value={fechaValoracion}
                        error={errores.fechaValoracion != null}
                        onChange={onChangeFechaValoracion}
                        autoComplete='off'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </FormControl>

                {cargaInicial &&
                    <>
                        <FormControl className='control'>
                            <TextFieldAct
                                required
                                id="txt-primer-anio"
                                placeholder="Ej. 2010"
                                label="Año del primer estudio actuarial"
                                helperText={errores.primerEstudio || 'Ingrese el año del primer estudio actuarial disponible del cliente'}
                                value={primerEstudio}
                                error={errores.primerEstudio != null}
                                onChange={onChangePrimerEstudio}
                                autoComplete='off'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl className='control'>

                            <FormLabel focused={false} className='label'>Reconocimiento inicial OBD en el primer estudio actuarial</FormLabel>

                            <RadioGroup row
                                name="rd-reconocimiento"
                                value={reconocimiento}
                                onChange={onChangeReconocimiento}>
                                <FormControlLabel className='radio-opcion' value="ERI" control={<Radio />} label="Reconocimiento inicial ERI" />
                                <FormControlLabel className='radio-opcion' value="ORI" control={<Radio />} label="Reconocimiento inicial ORI" />
                            </RadioGroup>
                        </FormControl>
                    </>
                }

                <FormControl className='control'>
                    {!cargaInicial && <FormLabel focused={false} className='label'>Cálculos automáticos</FormLabel>}

                    {!cargaInicial &&
                        <RadioGroup row
                            name="rd-calculos"
                            value={calculosAutomaticos}
                            onChange={onChangeCalculosAutomaticos}>
                            <FormControlLabel className='radio-opcion' value={true} control={<Radio />} label="Si" />
                            <FormControlLabel className='radio-opcion' value={false} control={<Radio />} label="No" />
                        </RadioGroup>
                    }

                    {cargaInicial &&
                        <Link style={{ fontSize: '0.8em', cursor: 'pointer' }} onClick={onClickVisualizarTasas}>Visualizar tasas de Impuesto a la Renta</Link>
                    }

                </FormControl>

                <FormControl className='control'>
                    <FormLabel focused={false} className='label'>Reemplazar</FormLabel>

                    <RadioGroup row
                        name="rd-reemplazar"
                        value={reemplazar}
                        onChange={onChangeReemplazar}>
                        <FormControlLabel className='radio-opcion' value="true" control={<Radio />} label="Si" />
                        <FormControlLabel className='radio-opcion' value="false" control={<Radio />} label="No" />
                    </RadioGroup>

                </FormControl>

                <FormControl>
                    <ButtonAct
                        color="naranja"
                        variant="contained"
                        component="label">
                        SELECCIONE ARCHIVO
                        <input onChange={onChangeArchivo}
                            type="file"
                            id="file-archivo"
                            hidden
                        />
                    </ButtonAct>
                    {archivoSeleccionado != null &&
                        <p className='parrafo'>{archivoSeleccionado.name}</p>
                    }
                </FormControl>

                <FormControl className='control-m0'>
                    <ButtonAct variant="contained"
                        disabled={deshabilitarBotonGuardar() || sesionCtx?.loading}
                        onClick={onClickCargarArchivo}
                        startIcon={sesionCtx?.loading && <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />}
                    >Cargar Archivo</ButtonAct>
                </FormControl>
            </FormAct>

            {modalVisible &&
                <Modal respuesta={respuesta} titulo="Carga de Archivo">
                    <div className="botones">

                        {respuesta.advertencias.length > 0 && respuesta.errores.length === 0 && (
                            <ButtonAct
                                variant="contained"
                                onClick={onClickContinuarConAdvertencias}
                                style={{ marginRight: '5px' }}
                                color="naranja"
                                disabled={sesionCtx.loading}
                            >
                                Cargar Archivo con Advertencias
                            </ButtonAct>
                        )}

                        <ButtonAct variant="contained" color="gris" onClick={onCloseModal}>
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            }

            {modalTasasVisible &&
                <Modal titulo="Tasas IR por año">
                    <div className="botones">

                        {valoresTasas.map((tasa, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <FormControl className='control'>
                                    <TextFieldAct
                                        required
                                        placeholder="Ej. 12.3%"
                                        label={`Tasa IR ${tasa.anio}`}
                                        helperText={erroresTasas[index]}
                                        value={tasa.tasaString}
                                        error={erroresTasas.length > 0 && erroresTasas[index] != ''}
                                        onChange={(event) => onChangeValoresTasa(event, index)}
                                        autoComplete='off'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                                {tasa.anio == 2018 && erroresTasas[index] == '' && valoresTasas[index].tasaString != '' && (
                                    <div key={`check-${tasa.anio}`} style={{ display: 'flex', width: '200px', margin: 'auto' }}>

                                        <label style={{ margin: 'auto', marginTop: '2px' }}>
                                            <input style={{ boxShadow: 'inset 0px 0px 1px 1px white, 0px 0px 1px 1px rgba(0, 0, 0, 0.23)' }}
                                                type="checkbox" checked={mismaTasaGeneral} onChange={(event) => onChangeAplicaMismaTasaGeneral(event, index)}
                                            />
                                            <span style={textoAplica}>
                                                Aplica la misma< br />
                                                tasa para todos los años
                                            </span>
                                        </label>


                                    </div>
                                )}
                            </div>

                        ))}


                        <div style={helperText}>Cálculos del activo por impuesto diferido, usos y reversos</div>


                        <ButtonAct
                            variant="contained"
                            onClick={onClickGuardarConTasas}
                            style={{ marginRight: '5px' }}
                            color="naranja"
                            disabled={sesionCtx.loading || deshabilitarBotonGuardarConTasas()}
                        >
                            Cargar Archivo
                        </ButtonAct>


                        <ButtonAct variant="contained" color="gris" onClick={onCloseModalTasas}>
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            }

            {modalTasasVisualizacionVisible &&
                <Modal titulo="Tasas IR por año">
                    <div className="botones">

                        {valoresTasasCargaInicial.map((tasa, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <FormControl className='control'>
                                    <TextFieldAct
                                        required
                                        label={`Tasa IR ${tasa.anio}`}
                                        value={tasa.tasa + "%"}
                                        autoComplete='off'
                                        InputLabelProps={{
                                            shrink: true,
                                            readOnly: true,
                                        }}
                                    />
                                </FormControl>
                            </div>

                        ))}


                        <div style={helperText}>Cálculos del activo por impuesto diferido, usos y reversos</div>


                        <ButtonAct variant="contained" color="gris" onClick={onCloseModalTasasVisualizacion}>
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            }

            {modalSimpleVisible && (
                <Modal respuesta={respuesta} titulo="Mensaje">
                    <div className="botones">
                        <ButtonAct variant="contained" onClick={onClickContinuar} color="naranja">
                            Continuar
                        </ButtonAct>
                        <ButtonAct variant="contained" onClick={onCloseModalSimple} color="gris">
                            Cerrar
                        </ButtonAct>
                    </div>
                </Modal>
            )}
        </Card>
    );
}

const helperText = {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '14px',
    letterSpacing: '0.03333em',
};

const textoAplica = {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: '10px',
    marginLeft: '5px',
    letterSpacing: '0.03333em',
    textAlign: 'left',
};

export default CargaDeArchivo;