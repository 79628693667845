import { initializeApp } from "firebase/app";
import firebaseConfig from '../config';
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";

import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";

/*console.log(firebaseConfig);*/

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "administradores"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "administradores"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logInWithEmailAndPassword = async (email, password) => {
   // try {
        await signInWithEmailAndPassword(auth, email, password);
   // } catch (err) {
    //    console.error(err);
      //  alert("ada"+err.message);
    //}
};

const sendPasswordReset = async (email) => {
    await sendPasswordResetEmail(auth, email);
};

const logout = async () => {
    await signOut(auth);
};

export {
    app,
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    sendPasswordReset,
    logout,
};