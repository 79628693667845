import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, logout } from "../lib/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { appConfig } from '../config';
import "./Login.css";
import axios from "axios";
import SesionContext from '../contexts/SesionContext';
import SideLogin from "./common/SideLogin";
import Card from "./common/Card";
import TextFieldAct from "./mui/TextFieldAct";
import { CircularProgress, FormControl } from "@mui/material";
import FormAct from "./common/FormAct";
import ButtonAct from "./mui/ButtonAct";
import { useCallback } from "react";

function Login() {

	const sesionCtx = useContext(SesionContext);
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [password, setPassword] = useState("");
	const [loadingButton, setLoadingButton] = useState(false);
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();

	const onClickLogin = useCallback(async () => {
		setLoadingButton(true);
		try {
			await logInWithEmailAndPassword(email, password);
			sesionCtx.cambiarSitioAdmin(false);
			setLoadingButton(false);
		} catch (error) {
			setError("Credenciales incorrectas")
			console.log("Error:"+error)
			setLoadingButton(false);
		}
	}, [email, password, sesionCtx])

	useEffect(() => {
		if (user && !loading) {
			const url = '/usuarios/es-rol-administrador/' + user.email + '/' + user.uid;

			axios.create({
				baseURL: appConfig.apiEndpont
			}).get(url).then(function (response) {
				if (response.data === true) {
					user.getIdTokenResult(true)
						.then((idTokenResult) => {
							// Confirm the user is an Admin.
							if (!!idTokenResult.claims.admin) {
								// Show admin UI.
								//showAdminUI();
								console.log("admin");
							} else {
								// Show regular user UI.
								//showRegularUI();
								console.log("NO admin");
							}
						})
						.catch((error) => {
							console.log(error);
						});
					if (sesionCtx.cliente.id == null) {
						navigate("/seleccion-de-cliente");
					} else {
						navigate("/carga-de-archivo");
					}

				} else {
					//entonces es cliente, obtiene los clientes asignados al usuario
					const urlClientesUsuarios = '/clientes/usuario/' + user.email;
					axios.create({ baseURL: appConfig.apiEndpont })
					.get(urlClientesUsuarios).then(function (response) {
						const clientes = response.data;
						if (clientes.length === 0) {
							setError("Usuario inactivo");
							//entonces cierra sesion
							sesionCtx.limpiarCliente();
							sesionCtx.cambiarSitioAdmin(false);
							logout();
							navigate('/');
						}
						if (clientes.length >= 1) {
							sesionCtx.cambiarCliente({ id: clientes[0].id, ruc: clientes[0].identificacion, nombre: clientes[0].nombre, nombreComercial: clientes[0].nombreComercial });
							sesionCtx.llenarClientesUsuario(clientes);
							navigate("/inicio");
						}
					}).catch(function (error) {
						console.error(error);
						//navigate("/error")
					});
				}
			}).catch(function (error) {
				//setError("Usuario inactivo")
				console.error(error);
				//navigate("/error")
			});
		}
	}, [user, loading, sesionCtx, navigate]);

	

	return (
		<div>
			<SideLogin />
			<Card className="login" titulo="Iniciar sesión">
				
				<FormAct onSubmit={(event) => { event.preventDefault() }}>
					<div>
						{error && <p className="error">{error}</p>}

						<FormControl className="control">
							<TextFieldAct
								id="txt-email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								label="Correo electrónico"
								InputLabelProps={{
									shrink: true
								}}
							/>
						</FormControl>
						<FormControl className="control">
							<TextFieldAct
								id="txt-pass"
								type="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								label="Contraseña"
								InputLabelProps={{
									shrink: true
								}}
							/>
						</FormControl>

						<FormControl>
							<ButtonAct
								variant="contained"
								onClick={onClickLogin}
								disabled={loadingButton || loading}
								startIcon={(loadingButton || loading) && <CircularProgress sx={{color: 'rgba(0, 0, 0, 0.26)'}} size={20} />}
							>
								INGRESAR
							</ButtonAct>
						</FormControl>

						<div style={{ fontSize: '14px', textAlign: 'right', marginTop: '4px' }}>
							<Link to="/reset">¿Olvidó su contraseña?</Link>
						</div>
					</div>
				</FormAct>
			</Card>
		</div>
	);
}
export default Login;