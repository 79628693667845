import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { usePromiseTracker } from "react-promise-tracker";
import { Audio } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import SesionContext from '../../contexts/SesionContext';
import { logout } from "../../lib/firebase";

import './Header.css'
import logo from '../../assets/images/logo-grande.png'
import salir from '../../assets/images/salir.png'
import cambiarCliente from '../../assets/images/cambiar-cliente.svg'
import itemSeleccion from '../../assets/images/item-seleccion.svg'
import Modal from '../common/Modal';
import ButtonAct from '../mui/ButtonAct';

const Header = (props) => {
  const navigate = useNavigate();
  const sesionCtx = useContext(SesionContext);
  const { promiseInProgress } = usePromiseTracker();
  const [modalCliente, setModalCliente] = useState(false);

  const cerrarSesion = useCallback(async () => {
    try {
      await logout();
      sesionCtx.limpiarCliente();
      sesionCtx.cambiarSitioAdmin(false);
      navigate('/');
    } catch (error) { }
  }, [sesionCtx, navigate]);

  const onClickCambiarCliente = () => {
    setModalCliente(true);
  }

  const onClickClienteSeleccionado = (cliente) => {
    sesionCtx.cambiarCliente({ id: cliente.id, ruc: cliente.identificacion, nombre: cliente.nombre, nombreComercial: cliente.nombreComercial });
    setModalCliente(false);
    navigate('/inicio');
  }

  const loadingIndicator = (load) => {
    let html;
    if (load) {
      html = (
        <div style={{ width: '20px', margin: 'auto' }}>
          <Audio
            height="20"
            width="20"
            radius="9"
            color='#DF5E2D'
            ariaLabel='three-dots-loading'
          />
        </div>
      );
    }
    return html;
  }

  const onCloseModalCliente = () => {
    setModalCliente(false);
  }

  return (
    <header>
      <div className="logo">
        <a href="/"><img src={logo} style={{width:'224px'}} alt='Logo Actuaria' /></a>

      </div>

      {props.user?.email &&
        <div className="titulo-plataforma">
          SISTEMA DIGITAL DE PROVISIONES
          <div className='sub-titulo-plataforma'>Kardex Actuarial e Impuestos Diferidos</div>
          <div style={{ height: '21px' }}>
            {loadingIndicator(sesionCtx?.loading || promiseInProgress)}
          </div>
        </div>
      }

      <div className="datos-usuario">
        <div>

          {props.user?.email && <input type="image" className="btn-cerrar" alt="Cerrar Sesión" title="Cerrar Sesión" onClick={cerrarSesion}
            src={salir} />}

          <div className='usuario'>{props.user?.email}</div>
        </div>
        {props.user?.email && sesionCtx.clientesUsuario.length <= 1 && <div className='nombre-cliente-enc' title={sesionCtx.cliente.id + "Ruc Cliente:" + sesionCtx.cliente.ruc}>{sesionCtx.cliente.nombre}</div>
        }

        {props.user?.email && sesionCtx.clientesUsuario.length > 1 &&
          <div style={{ cursor: 'pointer' }} onClick={onClickCambiarCliente}>
            <img style={{ float: 'right', height: '16px', marginLeft: '5px' }} src={cambiarCliente} alt="Cambiar Cliente" title="Cambiar Cliente" />
            <div className='nombre-cliente-enc'
              title={sesionCtx.cliente.id + "Ruc Cliente:" + sesionCtx.cliente.ruc}>
              {sesionCtx.cliente.nombre}
            </div>

          </div>
        }

      </div>

      {modalCliente && (
        <Modal titulo="Selección de Empresa">

          <p style={{ marginTop: '10px' }}>Haga click sobre la empresa que desea trabajar:</p>

          {sesionCtx.clientesUsuario.map((item, index) => (
            <div className="item-cambiar-cliente" onClick={() => onClickClienteSeleccionado(item)}
              key={index} title={"Ruc Cliente:" + item.identificacion}>
              <img src={itemSeleccion} alt="" title="" style={{ height: '14px' }}></img> {item.nombre}
            </div>
          ))}

          <br />
          <ButtonAct variant="contained" color="gris" onClick={onCloseModalCliente}>
            Cerrar
          </ButtonAct>

        </Modal>
      )}
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string
  })
}

export default Header;
