import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import SesionContext from "./SesionContext";

const SesionProvider = (props) => {

    const [cliente, setCliente] = useState({ id: null, ruc: null, nombre: null, nombreComercial: null });
    const [sitioAdmin,setSitioAdmin]=useState(false);
    const [loading, setLoading] = useState(false);
    const [clientesUsuario, setClientesUsuario] = useState([]);

    useEffect(() => {
        const storedCliente = localStorage.getItem('cliente');
        if (storedCliente != null) {
            setCliente(JSON.parse(storedCliente));
        } else { }
    }, []);

    const cambiarCliente = (nuevoCliente) => {
        const clienteCambiado = { ...nuevoCliente };
        localStorage.setItem("cliente", JSON.stringify(clienteCambiado));
        setCliente(previousState => {
            return clienteCambiado;
        });
    }

    const llenarClientesUsuario = (clientesUsuario) => {
        setClientesUsuario(clientesUsuario);
    }

    const limpiarCliente = () => {
        const clienteCambiado = { id: null, ruc: null, nombre: null, nombreComercial: null };
        localStorage.setItem("cliente", JSON.stringify(clienteCambiado));
        setCliente(clienteCambiado);
    }

    const cambiarSitioAdmin = (valor) => {
        setSitioAdmin(valor);
    }

    const cambiarLoading = (load) => {
        setLoading(load);
    };

    const sesionCtx = {
        sitioAdmin: sitioAdmin,
        cliente: cliente,
        loading,
        clientesUsuario: clientesUsuario,
        cambiarCliente: cambiarCliente,
        limpiarCliente: limpiarCliente,
        cambiarSitioAdmin: cambiarSitioAdmin,
        cambiarLoading,
        llenarClientesUsuario:llenarClientesUsuario
    }

    return (
        <SesionContext.Provider value={sesionCtx}>
            {props.children}
        </SesionContext.Provider>
    );
}

SesionProvider.propTypes={
    children: PropTypes.node
}

export default SesionProvider;
